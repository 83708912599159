const HYPEREAD_API_URL = 'https://api.hyperead.io/semantic-search-api/';
const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';
const OPENAI_API_KEY = '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'; // Don't keep this here for production!
const DEFAULT_LANGUAGE = "en";
const OPENAI_PROMPT = 'You are an academic assistant that write a book commentary from selected paragraphs, and answer a questions. For every sentence you write, cite the book name and paragraph Number. At the end of your commentary: 1. Add key words from the book paragraphs. 2. suggest a further question that can be answered by the paragraphs provided. 3. create a sources list of book names, paragraph Number author name, and a link for each book you cited. If the book paragraphs are not related to the question tell that to the reader, and suggest short questions about the paragraphs.';

export default class Hyperead {
    constructor(config = {}) {
        if (!config.file_name) {
            throw new Error('file_name is required to initialize Hyperead.');
        }

        this.config = {
            language: DEFAULT_LANGUAGE,
            ...config
        };
        this.api = HYPEREAD_API_URL;
    }

    async query(query, options = {}) {
        try {
            const response = await fetch(this.api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({
                    ...this.config,
                    ...options,
                    query,
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch from Hyperead API');
            }

            return await response.json();

        } catch (err) {
            console.error("Hyperead API error:", err);
            throw err;
        }
    }

    async openAIQuery(combinedInput) {
        const body = {
            model: 'gpt-4',
            messages: [
                {
                    'role': 'system',
                    'content': OPENAI_PROMPT,
                },
                { 'role': 'user', 'content': combinedInput },
            ],
        };

        try {
            const response = await fetch(OPENAI_API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${OPENAI_API_KEY}`
                },
                body: JSON.stringify(body)
            });

            if (!response.ok) {
                throw new Error('Failed to fetch from OpenAI API');
            }

            return await response.json();
        } catch (err) {
            console.error("OpenAI API error:", err);
            throw err;
        }
    }

    async fetchDataAndRender(query, resultsDiv, openAIResultsDiv) {
        try {
            const hypereadResponse = await this.query(query);

            // Add your rendering logic for 'resultsDiv' here based on 'hypereadResponse'

            let combinedInput = query + " " + hypereadResponse.answer_list.map(item => {
                return `${item.context} From the book "${item.article_name}" by ${item.author}. Paragraph Index: ${item['paragraph index']}`;
            }).join(" ");

            const openAIResponse = await this.openAIQuery(combinedInput);

            openAIResultsDiv.innerHTML = openAIResponse.choices[0].message.content.replace(/\n/g, '<br>');

        } catch (error) {
            console.error("Error during data fetch and render:", error);
        }
    }
}
